import { UserInfoData } from "~/generated/data-contracts";

export const useSub = () => {
  const me = useMe();

  const hasSubscription = computed(() => {
    if (!me.value) return false;
    if (me.value.bypassSubscription) return true;
    return me.value.activeSubscription;
  });

  return { hasSubscription };
};
